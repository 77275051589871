import React, { useEffect, useState } from 'react';
import abi from '../../abi/stakingContractAbi.json';
import { useConnectWallet } from '@web3-onboard/react';
import { ethers } from 'ethers';
import { ToastContainer, toast } from 'react-toastify';

export default function BoostNft(props) {
    const [{ wallet }] = useConnectWallet();
    const [isLoading2, setIsLoading2] = useState(false);
    const [boostNftAddr, setBoostNftAddr] = useState('');


    const etherProvider = () => {
        try {
            return new ethers.providers.Web3Provider(wallet?.provider);
        } catch (error) {
            console.log('🚀 ~ StakingPool ~ error', error);
        }
    };
    const etherSigner = etherProvider()?.getSigner();
    const standardtokenContract = new ethers.Contract(
        props?.address?.toLowerCase(),
        abi,
        etherSigner
    );


    const handleBoostNft = async () => {
        try {
            setIsLoading2(true);
            let fee = await standardtokenContract
                .setBoostNFT(boostNftAddr)

            let waitFortx = await fee.wait();
            if (waitFortx) {
                setIsLoading2(false);
                toast.success('Transaction successful!')

            }
        } catch (error) {
            let _stringify = JSON.stringify(error);
            let _parse = JSON.parse(_stringify);
            if (_parse?.error?.data?.message) {
                toast.error(_parse?.error?.data?.message);
            }
            console.log('🚀 ~ consthandleBoostNft= ~ error', error);
            setIsLoading2(false);

        }
    };



    return (
        <>
            <ToastContainer />
            <div className='container'>


                <h5 className='mx-md-3 mb-3'>Set boost NFT</h5>
                <div className='row '>


                    <div className='col-6  '>
                        <div className='form-group'>
                            <label className='' htmlFor='name'>Enter NFT address</label>
                            <input
                                type='text'
                                id='boostNftAddr'
                                name='boostNftAddr'
                                placeholder="Enter address "
                                required='required'
                                value={boostNftAddr}
                                onChange={(e) => setBoostNftAddr(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    {isLoading2 ? (
                        <div className='spinner-border' role='status'>
                            <span className='visually-hidden'></span>
                        </div>
                    ) : (
                        <button
                            className='btn btn-primary active'
                            onClick={handleBoostNft}
                        >
                            submit
                        </button>
                    )}
                </div>
            </div>


        </>
    );
}
