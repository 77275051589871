export const networkHashMap = Object.freeze({
  "0x1": 1,
  "0x3": 3,
  "0x4": 4,
  "0x5": 5,
  "0x2a": 42,
  "0x97": 97,
  "0x61": 61,
  "0x89": 137,
  "0x13881": 80001,
  "0xA86A": 43114,
  "0xA869": 43113,
  "0x440": 1088,
  "0x24C": 588,
  "0x4E454152": 1313161554,
  "0x4E454153": 1313161555,
  "0x38": 56,
  "0x152": 338,
  "0x19": 25,
});
