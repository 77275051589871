import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const BASE_URL = "https://my-json-server.typicode.com/j0ey1992/roo.json/footer";

// const footerData = {
//   img: "/img/logo.png",
//   copyright: "©2024 Shinu Dapp, All Rights Reserved By",
//   owner: "Shinu",
//   ownerLink: "https://Roo.Finance",
// };

// const socialData = [
//   {
//     id: 1,
//     link: "https://www.facebook.com/profile.php?id=100087503031319",
//     icon: "icon-social-facebook",
//   },
//   {
//     id: 2,
//     link: "https://twitter.com/RooFinance",
//     icon: "icon-social-twitter",
//   },
//   {
//     id: 3,
//     link: "https://www.youtube.com/channel/UCkH9DCBcjovuQg3tYbLnYTQ",
//     icon: "icon-social-youtube",
//   },
// ];
const widgetData = [
  // {
  //   id: 1,
  //   text: "Launchpad",
  //   link: "https://roo.finance/projects",
  // },
  // {
  //   id: 2,
  //   text: "Roadmap",
  //   link: "https://roo-finance.gitbook.io/roadmap./",
  // },
  // {
  //   id: 3,
  //   text: "Staking Application",
  //   link: "https://forms.gle/stsBdKN3hrMdWPHNA",
  // },
  // {
  //   id: 4,
  //   text: "Blog",
  //   link: "https://medium.com/@KangaDegens",
  // },
  // {
  //   id: 5,
  //   text: "Founder",
  //   link: "https://medium.com/@KangaDegens/team-of-roo-finance-6aab1398712",
  // },
];

class Footer extends Component {
  state = {
    data: {},
    socialData: [],
    widgetData: [],
  };
  componentDidMount() {
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          data: res.data,
          socialData: res.data.socialData,
          widgetData: res.data.widgetData,
        });
        // console.log(this.state.data)
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <footer className="footer-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 text-center">
              {/* Footer Items */}
              <div className="footer-items">
                {/* Logo */}
                <Link to="/" className="navbar-brand">
                  <img src={this.state.data.img} height={60} width={60} alt="" />

                </Link>
                {/* Social Icons */}
                <div className="social-icons d-flex justify-content-center my-4">
                  <a

                    className="discord"
                    href={'https://discord.com/invite/PtSxQQ2M'}
                    target="_blank"
                  >
                    <i >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-discord " viewBox="0 0 16 16">
                        <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612" />
                      </svg>
                    </i>
                    <i >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-discord " viewBox="0 0 16 16">
                        <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612" />
                      </svg>
                    </i>

                  </a>
                  <a

                    className="twitter"
                    href={'https://twitter.com/ShikokuInuCro?s=20'}
                    target="_blank"
                  >
                    <i >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                      </svg>
                    </i>
                    <i >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                      </svg>
                    </i>

                  </a>
                  {/* {this.state.socialData.map((item, idx) => {
                    return (
                      <a
                        key={`fsd_${idx}`}
                        className="facebook"
                        href={item.link}
                        target="_blank"
                      >
                        <i className={item.icon} />
                        <i className={item.icon} />
                      </a>
                    );
                  })} */}
                </div>
                <ul className="list-inline">
                  {widgetData.map((item, idx) => {
                    // console.log("item.link", item.link);
                    return (
                      <li key={`fwd_${idx}`} className="list-inline-item">
                        {item.text == "Blog" || item.text == "Roadmap" ? (
                          <a href={item.link}>{item.text}</a>
                        ) : (
                          <Link to={item.link}>{item.text}</Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
                {/* Copyright Area */}
                <div className="copyright-area py-4">
                  {/* {this.state.data.copyright}{" "} */}
                  ©2024 Shinu, All Rights Reserved

                  <a href={this.state.data.ownerLink} target="_blank">
                    {/* {this.state.data.owner} */}
                  </a>
                </div>
              </div>
              {/* Scroll To Top */}
              <div id="scroll-to-top" className="scroll-to-top">
                <a href="#header" className="smooth-anchor">
                  <i className="fa-solid fa-arrow-up" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
