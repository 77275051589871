import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import Web3 from 'web3';
import abi from '../../abi/stakingContractAbi.json';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import axios from 'axios';
import { saveCollection } from '../../redux/action';
import { useDispatch } from 'react-redux';
import './staking.css';

// const BASE_URL = "https://my-json-server.typicode.com/j0ey1992/roo1/farming";

const StakingTwo = () => {


  const reduxDispatch = useDispatch();

  const [renderData, setRenderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const { contract_address } = renderData;
  const [sortBy, setSortBy] = useState('');
  const [apiData, setApiData] = useState([]);

 
  const userData = useSelector((state) => state?.state);
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsCompleted(true);
      return (
        <span
          className="complete_countdown_text"
          style={{
            fontSize: '12px'
          }}
        >
          Countdown complete!
        </span>
      );
    } else {
      return (
        <span
          className="time_part"
          style={{
            fontSize: '12px'
          }}
        >
          {days}d {hours}h:{minutes}m:{seconds}s
        </span>
      );
    }
  };

  // useEffect(() => {
  //   console.log("userData", userData);
  // }, [userData]);

  async function getCollectionData() {
    let collectionData = await axios.get(`${process.env.REACT_APP_API_URL}nfts`);
    console.log("collectionData:", collectionData)
    reduxDispatch(
      saveCollection({
        collections: collectionData?.data
      })
    );
    // console.log("collectionData:", collectionData)
    setRenderData(collectionData?.data);
    setApiData(collectionData?.data)
    // console.log('🚀 ~ getCollectionDataa ~ collectionData', collectionData);
    setIsLoading(false);


    // await GetCollections({
    //   variables: {},
    //   onCompleted: (data) => {
    //     console.log("collections", data);
    //     setRenderData(data.Collections);
    //     setIsLoading(false);
    //   },
    // });
  }

  useEffect(() => {
    getCollectionData();
  }, []);
  const handleNftSearch = (text) => {
    if (text.length > 0) {
      const matchingObjects = renderData?.filter((object) => {
        for (const prop in object) {
          const propValue = object[prop].toString()?.toLowerCase();
          for (const keyword of [text]) {
            if (propValue.includes(keyword?.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      });
      if (matchingObjects.length > 0) {
        setRenderData(matchingObjects);
      }
    } else {
      setRenderData(apiData);
    }
  };
  const handleSortNft = (val) => {
    if (val === 'Sort by') {
      setSortBy(val);
      return '';
    }
    let sortByAsc = [];
    if (val === 'asc') {
      sortByAsc = renderData
        ?.slice()
        .sort((e, i) => parseInt(e?.expire_date) - parseInt(i?.expire_date));
    }
    if (val === 'desc') {
      sortByAsc = renderData
        ?.slice()
        .sort((e, i) => parseInt(i?.expire_date) - parseInt(e?.expire_date));
    }
    setRenderData(sortByAsc);
    setSortBy(val);
  };

  const currTime = Math.floor(Date.now());
  const extractImageId = (addr) => {
    // console.log('🚀 ~ extractImageId ~ addr', addr)
    let url = addr.split('/')[5];
    // console.log('🚀 ~ extractImageId ~ url', url)
    return url;
  };

  const tabs = [
    {
      title: 'Live',
      active: 'active',
      show: 'show',
      content: (
        <div className="row justify-content-center live_1">
          {console.log("renderData:", renderData)}
          {renderData?.filter((a) => a.expire_date > currTime)

            .map((item, idx) => {
              const futureDate = parseInt(item?.expire_date);

              // need to change when deploy
              if (true) {
                return (
                  <div
                    key={`pdt_${idx}`}
                    className="col-12 col-md-4 col-sm-6 myCol  col-lg-2 myColDiv  item "
                  >
                    <div className="card project-card prev-project-card previous_launches_list_wrapper p-2 rounded-1">
                      <div className="project-content">
                        <div className="item-header  ">
                          <img
                            className="card-img-top avatar-max-lg"
                            src={
                              item?.picture
                                ? `https://drive.google.com/thumbnail?id=${extractImageId(
                                  item?.picture
                                )}`
                                : '/img/placeholder.png'
                            }
                            alt=""
                          />

                          <div className="card_img_content pt-4 text-center">
                            <p className="m-0 fw-bold ">{item?.name}</p>
                            {/* <span className="symbol">{item?.symbol}</span> */}
                            {/* <span className="address">
                              address:
                              <a
                                href={`https://testnet.cronoscan.com/address/${contract_address}`}
                                target={"_blank"}
                              >
                                {" "}
                                {item?.contract_address.substring(0, 5)}...
                                {item?.contract_address.substring(37, 42)}
                              </a>
                            </span> */}
                            {/* <p className="description">{item?.description}</p> */}
                            <small>
                              {item?.expire_date ? (
                                <Countdown
                                  date={new Date(parseInt(item?.expire_date))}
                                  intervalDelay={0}
                                  precision={3}
                                  renderer={renderer}
                                />
                              ) : (
                                ''
                              )}
                            </small>
                          </div>
                        </div>
                      </div>
                      <Link
                        to={`/${item?.contract_address}`}
                        className="project-link"
                      />
                    </div>
                  </div>
                );
              }
            })}
          {/* </div> */}
        </div>
      )
    },
    {
      title: 'Finished',
      active: '',
      show: '',
      content: (
        <div className="row justify-content-center live_1">
          {/* <div className="col-12"> */}
          {/* Single Accordion Item */}
          {renderData?.map((item, idx) => {
            const futureDate = parseInt(item?.expire_date);
            if (futureDate < currTime) {
              return (
                <div
                  key={`pdt_${idx}`}
                  className="col-12 col-md-4 col-sm-6 myCol  col-lg-2 myColDiv  item "
                >
                  <div className="card project-card prev-project-card previous_launches_list_wrapper p-2 rounded-1">
                    <div className="project-content">
                      <div className="item-header ">
                        <img
                          className="card-img-top avatar-max-lg"
                          src={
                            item?.picture
                              ? `https://drive.google.com/thumbnail?id=${extractImageId(
                                item?.picture
                              )}`
                              : '/img/placeholder.png'
                          }
                          alt=""
                        />

                        <div className="card_img_content pt-2">
                          <p className="m-0 fw-bold">{item?.name}</p>
                          {/* <span className="symbol">{item?.symbol}</span> */}
                          {/* <span className="address">
                              address:
                              <a
                                href={`https://testnet.cronoscan.com/address/${contract_address}`}
                                target={"_blank"}
                              >
                                {" "}
                                {item?.contract_address.substring(0, 5)}...
                                {item?.contract_address.substring(37, 42)}
                              </a>
                            </span> */}
                          {/* <p className="description">{item?.description}</p> */}
                        </div>
                        <small>
                          <Countdown
                            date={new Date(parseInt(item?.expire_date))}
                            intervalDelay={0}
                            precision={3}
                            renderer={renderer}
                          />
                        </small>
                      </div>
                    </div>
                    <Link
                      to={`/${item?.contract_address}`}
                      className="project-link"
                    />
                  </div>
                </div>
              );
            }
          })}
          {/* </div> */}
        </div>
      )
    }
    // {
    //   title:'Sort By',
    //   active:'',
    //   show:'',
    //   content:(
    //     <>
    //      <select
    //             // onChange={(e) => handleSortNft(e.target.value)}
    //             class=" mySelectBox"
    //             aria-label="Default select example"
    //             // value={sortBy}
    //           >
    //             <option selected>Sort by</option>
    //             <option value="asc">Almost Finished</option>
    //             <option value="desc">Longest time available</option>
    //           </select>
    //     </>
    //   )
    // }
  ];

  return (
    <section className="staking-area">
      <div className="container-fluid">
        <div className="row stack_row_box justify-content-center">
          <div className="col-12 col-md-12">
            {/* Tab_Start */}
            <div id="gameon-accordion" className="accordion">
              <ul className="nav nav-tabs tab_header_inner" role="tablist">
                {tabs.map((item, id) => {
                  return (
                    <li className="nav-item" key={id}>
                      <a
                        className={`nav-link ${item.active}`}
                        id={`${item.title}-tab`}
                        data-toggle="tab"
                        href={`#${item.title}`}
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        {item.title}
                      </a>
                    </li>
                  );
                })}
                <li>
                  <div className=" ml-3 ">
                    <select
                      onChange={(e) => handleSortNft(e.target.value)}
                      className=" mySelectBox"
                      aria-label="Default select example"
                      value={sortBy}
                    >
                      <option selected>Sort by</option>
                      <option value="asc">Almost Finished</option>
                      <option value="desc">Longest time available</option>
                    </select>
                  </div>
                </li>
                <li className='ml-3'>
                  <form className="myNftSearchBox" role="search">
                    <input
                      className="form-control "
                      type="search"
                      placeholder="Search Nft"
                      aria-label="Search"
                      onChange={(e) => handleNftSearch(e.target.value)}
                    />
                  </form>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                {tabs.map((item, id) => {
                  return (
                    <div
                      className={`tab-pane fade ${item.show} ${item.active}`}
                      id={`${item.title}`}
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      key={id}
                    >
                      {item.content}
                    </div>
                  );
                })}
              </div>
            </div>
            {/* Tab_End */}

            {isLoading && (
              <div className="col-12 text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden"></span>
                </div>
              </div>
            )}

            {/* <div id="gameon-accordion" className="accordion">
              <Tabs />
              
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StakingTwo;
